import React from 'react'

import SslLogo from '../../assets/img/ssl.svg'
import StripeLogo from '../../assets/img/powered_by_stripe.svg'

const TrustSeals = () => {
  const trustSeal = {
    height: 25,
    marginRight: 15,
  }

  return (
    <div className="center">
      <img src={SslLogo} alt="SSL Secured" style={trustSeal} />
      <a
        href="https://aws.amazon.com/what-is-cloud-computing"
        rel="noopener noreferrer"
        target="_blank"
      >
        <img
          src="https://d0.awsstatic.com/logos/powered-by-aws.png"
          alt="Powered by AWS Cloud Computing"
          style={trustSeal}
        />
      </a>
      <a
        href="https://stripe.com"
        rel="noopener noreferrer"
        target="_blank"
      >
        <img src={StripeLogo} alt="Powered By Stripe" style={trustSeal} />
      </a>
    </div>
  )
}

export default TrustSeals
