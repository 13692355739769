import { Select } from 'antd'
import { capitalize } from 'lodash'
import React, { forwardRef, useState } from 'react'

import StateDatabase from '../../assets/doc/states.json'

// convert state database from an object to an array
// this is easier to filter counties for the Select component

const CountySelect = ({
  onChange, // eslint-disable-line react/prop-types
  placeholder, // eslint-disable-line react/prop-types
  state, // eslint-disable-line react/prop-types
  value, // eslint-disable-line react/prop-types
}, ref) => {
  const CountyDatabaseArray = []

  if (state) {
    StateDatabase['united states'][state].counties.forEach((v) => (
      CountyDatabaseArray.push(v.toLowerCase())
    ))
  }
  const [county, setCounty] = useState(value)
  const [filteredCounties, setFilteredCounties] = useState([])

  const handleChange = (newState) => {
    setCounty(newState)

    if (onChange) {
      onChange(newState)
    }
  }

  const handleSearch = (filter) => {
    if (filter && filter.length > 2) {
      const fc = CountyDatabaseArray.filter((c) => {
        const filterLower = filter.toLowerCase()
        return c.includes(filterLower)
      })

      setFilteredCounties(fc)
    }
  }

  return (
    <Select
      notFoundContent={null}
      onChange={handleChange}
      onSearch={handleSearch}
      placeholder={placeholder}
      ref={ref}
      showSearch
      value={county}
      autoComplete="off"
      className="custom-dropdown"
    >
      {filteredCounties.map((c) => (
        <Select.Option key={c} value={c}>
          {capitalize(c)}
        </Select.Option>
      ))}
    </Select>
  )
}

export default forwardRef(CountySelect)
