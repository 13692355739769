import {
  Button,
  Col,
  Form,
  message,
  Row,
} from 'antd'
import FormItem from 'antd/lib/form/FormItem'
import EmailValidator from 'email-validator'
import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import PropTypes from 'prop-types'
import Dropdown from '../CustomInputElements/Dropdown'
import RoundInputBox from '../CustomInputElements/RoundInputBox'
import TermCheckbox from '../RaffleDetails/TermCheckbox'
import Recaptcha from '../Recaptcha'
import StateSelect from './StateSelect'
import CountySelect from './CountySelect'

import { categoriesJson } from '../../constants/raffle'
import RaffleActions from '../../actions/raffles'
import TrustSeals from '../TrustSeals'

const CantFindForm = ({ inActiveStyle, activeIndustry, onViewChange }) => {
  const [loading, setLoading] = useState(false)
  const [categories, setCategories] = useState(Object.keys(categoriesJson[activeIndustry]))
  const [subCategories, setSubCategories] = useState(categoriesJson[activeIndustry][categories[0]] || [])
  const [state, setState] = useState()

  const intl = useIntl()
  const [form] = Form.useForm()

  const handleFinish = async (values) => {
    const email = values.email.trim()

    if (email && EmailValidator.validate(email)) {
      try {
        setLoading(true)
        await RaffleActions.interest({ ...values, industry: activeIndustry })
        setLoading(false)

        form.resetFields()
        onViewChange({
          view: 'success',
          title: 'common.thanks-for-reaching-out',
          message: 'comp.cantfindpage.form.let-you-know',
        })
      } catch (ex) {
        setLoading(false)
        const { response } = ex
        if (response.status === 409) {
          onViewChange({
            view: 'success',
            title: 'common.thank-you',
            message: 'comp.cantfindpage.form.already-entered',
          })
        } else {
          message.warn(ex.message)
        }
      }
    } else {
      const msg = intl.formatMessage({ id: 'comp.rafflesidebar.invalidemail' })
      message.warn(msg)
    }
  }

  const handleTabChange = (industry) => {
    setCategories(Object.keys(categoriesJson[industry]))
    onViewChange({ industry })
  }

  return (
    <>
      <Col xs={24} md={24} className="center">
        <Row className="center" justify="center">
          <Col xs={24} md={8} className="center">
            <Form form={form} layout="vertical" onFinish={handleFinish}>
              <Col xs={24} md={24}>
                <Button
                  className="mr1"
                  onClick={() => handleTabChange('health')}
                  shape="round"
                  size="medium"
                  style={activeIndustry !== 'health' ? inActiveStyle : {}}
                  type="primary"
                >
                  <FormattedMessage id="industry.health" />
                </Button>
                <Button
                  onClick={() => handleTabChange('leisure')}
                  shape="round"
                  size="medium"
                  style={activeIndustry !== 'leisure' ? inActiveStyle : {}}
                  type="primary"
                >
                  <FormattedMessage id="industry.leisure" />
                </Button>
              </Col>
              <Col xs={24} md={24} className="mt2">
                {activeIndustry === 'health' && (
                <>
                  <FormItem
                    name="category"
                    rules={[{ required: true }]}
                  >
                    <Dropdown
                      dropdownList={categories}
                      onChange={(newCategory) => {
                        if (categories.includes(newCategory)) {
                          setSubCategories(categoriesJson[activeIndustry][newCategory])
                        }
                      }}
                      placeholder="Select a Category"
                    />
                  </FormItem>

                  <FormItem
                    name="subcategory"
                    rules={[{ required: true }]}
                  >
                    <Dropdown dropdownList={subCategories} placeholder="Select a Sub Category" />
                  </FormItem>

                </>
                )}
                {activeIndustry === 'leisure' && (
                <>
                  <FormItem
                    name="category"
                    rules={[{ required: true }]}
                  >
                    <Dropdown
                      dropdownList={categories}
                      placeholder="Select a Category"
                    />
                  </FormItem>

                  <FormItem
                    name="states"
                    rules={[{ required: true }]}
                  >
                    <StateSelect
                      countries={['United States']}
                      placeholder="Enter State Here"
                      onChange={(newState) => setState(newState)}
                    />
                  </FormItem>

                  <FormItem
                    name="counties"
                    rules={[{ required: true }]}
                  >
                    <CountySelect state={state} placeholder="Enter County Here" />
                  </FormItem>
                </>
                )}
                <FormItem
                  name="email"
                  rules={[{ required: true }]}
                >
                  <RoundInputBox placeholder="Enter Your Email Here" />
                </FormItem>
                <Form.Item
                  name="recaptcha"
                  rules={[{ required: true }]}
                >
                  <Recaptcha />
                </Form.Item>
                <Form.Item
                  name="agreement"
                  rules={[
                    {
                      required: true,
                      type: 'boolean',
                    },
                  ]}
                  valuePropName="checked"
                >
                  <TermCheckbox />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" loading={loading} htmlType="submit" shape="round" size="large">
                    <span>
                      <FormattedMessage id="comp.cant-find.submit" />
                    </span>
                  </Button>
                </Form.Item>
              </Col>
            </Form>
          </Col>
        </Row>
      </Col>
      <Col xs={24} md={24}>
        <Row className="center" justify="center">
          <Col xs={24} md={10} className="center">
            <TrustSeals />
          </Col>
        </Row>
      </Col>
    </>
  )
}

CantFindForm.propTypes = {
  inActiveStyle: PropTypes.object.isRequired,
  activeIndustry: PropTypes.string.isRequired,
  onViewChange: PropTypes.func.isRequired,
}

export default CantFindForm
