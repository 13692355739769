import { Checkbox } from 'antd'
import { StaticQuery, graphql } from 'gatsby'
import React, { forwardRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'

// eslint-disable-next-line react/prop-types
const TermCheckbox = ({ onChange, value }, ref) => {
  const [checked, setChecked] = useState(value)

  const handleChange = (event) => {
    const newChecked = event.target.checked
    setChecked(newChecked)

    if (onChange) {
      onChange(newChecked || undefined)
    }
  }

  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              siteUrl
            }
          }
        }
      `}
      render={(data) => {
        const { siteUrl } = data.site.siteMetadata

        return (
          <div ref={ref}>
            <Checkbox onChange={handleChange} checked={checked}>
              <FormattedMessage id="comp.entrantform.i-agree-to" />
            </Checkbox>
            <a
              href={`${siteUrl}/terms-of-use`}
              rel="noopener noreferrer"
              target="_blank"
            >
              <FormattedMessage id="comp.layout.terms-of-use" />
            </a>
            <FormattedMessage id="comp.entrantform.and" />
            <a
              href={`${siteUrl}/privacy-policy`}
              rel="noopener noreferrer"
              target="_blank"
            >
              <FormattedMessage id="comp.layout.privacy-policy" />
            </a>
            .
          </div>
        )
      }}
    />
  )
}

export default forwardRef(TermCheckbox)
