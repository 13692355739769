import Cache from '@aws-amplify/cache'
import {
  Col,
  Row,
  Typography,
  Result,
} from 'antd'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import Layout from '../components/Layout'
import CantFindForm from '../components/CantFindForm'
import QuickLinks from '../components/QuickLinks'

const { Title, Paragraph } = Typography
const industryCacheKey = 'rsk-web.industry'

const CantFindPage = () => {
  const [activeIndustry, setActiveIndustry] = useState(Cache.getItem(industryCacheKey) || 'health')
  const [view, setView] = useState('form')
  const [title, setTitle] = useState('common.thanks-for-reaching-out')
  const [message, setMessage] = useState('comp.cantfindpage.form.let-you-know')

  useEffect(() => {
    if (activeIndustry) {
      Cache.setItem(industryCacheKey, activeIndustry)
    }
  }, [activeIndustry])

  const handleViewChange = ({
    view: newView, industry: newIndustry, title: newTitle, message: newMessage,
  }) => {
    if (newIndustry) {
      setActiveIndustry(newIndustry)
    }

    if (newView) {
      setView(newView)
      setTitle(newTitle)
      setMessage(newMessage)
    }
  }

  const inActive = { background: '#4e4e4e', borderColor: '#4e4e4e' }

  const renderView = () => {
    if (view === 'form') {
      return (
        <>
          <Row>
            <QuickLinks />
          </Row>
          <Row className="center" justify="center" align="center">
            <Col xs={24} md={24}>
              <Title level={2}>
                <FormattedMessage id="page.cant-find.title" />
              </Title>
            </Col>
            <Col xs={24} md={24}>
              <Paragraph>
                <FormattedMessage id="page.cant-find.description" values={{ br: <br /> }} />
              </Paragraph>
            </Col>

            <CantFindForm inActiveStyle={inActive} activeIndustry={activeIndustry} onViewChange={handleViewChange} />
          </Row>
        </>
      )
    }
    return (
      <Result
        status="success"
        subTitle={(
          <Typography.Title level={5}>
            <FormattedMessage id={`${message}`} />
          </Typography.Title>
        )}
        title={<FormattedMessage id={`${title}`} />}
      />
    )
  }

  return (
    <Layout selectedMenuKey="cant-find">
      <div className="container my3">
        {renderView()}
      </div>
    </Layout>
  )
}

export default CantFindPage
