import { Select } from 'antd'
import { capitalize } from 'lodash'
import React, { forwardRef, useState } from 'react'

import StateDatabase from '../../assets/doc/states.json'

// convert state database from an object to an array
// this is easier to filter counties for the Select component

const StateSelect = ({
  countries, // eslint-disable-line react/prop-types
  onChange, // eslint-disable-line react/prop-types
  placeholder, // eslint-disable-line react/prop-types
  size, // eslint-disable-line react/prop-types
  style, // eslint-disable-line react/prop-types
  value, // eslint-disable-line react/prop-types
}, ref) => {
  const StateDatabaseArray = []

  // eslint-disable-next-line react/prop-types
  countries.forEach((c) => {
    StateDatabase[c.toLowerCase()].states.forEach((v) => (
      StateDatabaseArray.push(v.toLowerCase())
    ))
  })
  const [state, setState] = useState(value)
  const [filteredStates, setFilteredStates] = useState([])

  const handleChange = (newState) => {
    setState(newState)

    if (onChange) {
      onChange(newState)
    }
  }

  const handleSearch = (filter) => {
    if (filter && filter.length > 2) {
      const fc = StateDatabaseArray.filter((s) => {
        const filterLower = filter.toLowerCase()
        return s.includes(filterLower)
      })

      setFilteredStates(fc)
    }
  }

  return (
    <Select
      notFoundContent={null}
      onChange={handleChange}
      onSearch={handleSearch}
      placeholder={placeholder}
      ref={ref}
      showSearch
      value={state}
      autoComplete="off"
      style={style || {}}
      size={size || 'middle'}
      className="custom-dropdown"
    >
      {filteredStates.map((s) => (
        <Select.Option key={s} value={s}>
          {capitalize(s)}
        </Select.Option>
      ))}
    </Select>
  )
}

export default forwardRef(StateSelect)
