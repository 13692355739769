import { Select } from 'antd'
import React, { forwardRef } from 'react'
import './customInputElements.css'

const { Option } = Select

const Dropdown = ({
  dropdownList, // eslint-disable-line react/prop-types
  onChange, // eslint-disable-line react/prop-types
  placeholder, // eslint-disable-line react/prop-types
  style, // eslint-disable-line react/prop-types
}, ref) => {
  const handleChange = (event) => {
    if (onChange) {
      onChange(event)
    }
  }

  if (dropdownList) {
    return (
      <Select
        className="custom-dropdown"
        onChange={handleChange}
        ref={ref}
        key={dropdownList[0]}
        placeholder={placeholder}
        style={style}
      >
        {/* eslint-disable-next-line react/prop-types */}
        {dropdownList.map((item) => (
          <Option value={item} key={item}>{item}</Option>
        ))}
      </Select>
    )
  }

  return null
}

export default forwardRef(Dropdown)
